import React, { Component } from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LatestProductsListDisplay from "./RedirectOpen";
function wait(timeout) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}
function rakutien() {
  var _rakuten_automate = {
    accountKey:
      "8f1f99ce59ce251e2d17e5b901e2dcb7ee21811310276b2ef7b62f2f14eb4e9b",
    u1: "",
    snippetURL: "https://automate-frontend.linksynergy.com/minified_logic.js",
    automateURL: "https://automate.linksynergy.com",
    widgetKey: "QRdyA7MUI1VQRBwzIIMtL25AbXvRs9wI",
    aelJS: null,
    useDefaultAEL: false,
    loaded: false,
    events: [],
  };
  var ael = window.addEventListener;
  window.addEventListener = function (a, b, c, d) {
    "click" !== a && _rakuten_automate.useDefaultAEL
      ? ael(a, b, c)
      : _rakuten_automate.events.push({
          type: a,
          handler: b,
          capture: c,
          rakuten: d,
        });
  };
  _rakuten_automate.links = {};
  var httpRequest = new XMLHttpRequest();
  httpRequest.open("GET", _rakuten_automate.snippetURL, !0);
  httpRequest.timeout = 5e3;
  httpRequest.ontimeout = function () {
    if (!_rakuten_automate.loaded) {
      for (var i = 0; i < _rakuten_automate.events.length; i++) {
        var a = _rakuten_automate.events[i];
        ael(a.type, a.handler, a.capture);
      }
      _rakuten_automate.useDefaultAEL = !0;
    }
  };
  // httpRequest.onreadystatechange = () => {
  //   if (eval !== undefined) {
  //     if (
  //       httpRequest.readyState === XMLHttpRequest.DONE &&
  //       200 === httpRequest.status &&
  //       (eval(httpRequest.responseText), _rakuten_automate.run(ael))
  //     );
  //   }
  // };
  httpRequest.send(null);
}
function affilate_scripts_adding() {
  var script_amascr = document.createElement("script");

  script_amascr.setAttribute(
    "src",
    "//www.anrdoezrs.net/am/9238616/include/allCj/generate/onLoad/impressions/page/am.js"
  );
  document.body.appendChild(script_amascr);
  // skim affilates
  var script_amascr = document.createElement("script");

  script_amascr.setAttribute(
    "src",
    "https://s.skimresources.com/js/146926X1611845.skimlinks.js"
  );
  document.body.appendChild(script_amascr);
}
class RedirectProduct extends Component {
  state = {
    product: {},
    loading: false,
  };
  openlocation = async (product) => {
    await wait(3000);
    if (product.store_id.id === 1) {
      const { product_id } = this.state.product;
      window.location.href = `${product_id}/?tag=ajarstore-21`;
    } else {
      document.getElementById("productClickLink").click();
    }
  };

  fetchHeadCarouselProducts = () => {
    this.setState({ loading: true }, () => {
      const productID = this.props.match.params.productID;
      axios
        .get(`https://api.ajarstore.com/api/items/${productID}/`)
        .then((res) => {
          this.setState({
            product: res.data,
            loading: false,
          });
          console.log(this.state.product);
          this.openlocation(this.state.product);
        });
    });
  };
  componentDidMount() {
    this.fetchHeadCarouselProducts();
  }
  render() {
    return (
      <>
        {this.state.loading ? (
          <h1></h1>
        ) : (
          <div>
            {/* <CircularProgress /> */}
            <div className="mdl-grid gc1">
              <div className="mdl-cell mdl-cell--4-col-desktop mdl-cell--2-col-tablet mdl-cell--0-col-phone"></div>
              <div className="mdl-cell mdl-cell--4-col-desktop mdl-cell--4-col-tablet mdl-cell--12-col-phone">
                <Paper
                  variant="outlined"
                  elevation={3}
                  style={{ marginTop: "20px", backgroundColor: "#fff" }}
                >
                  <LinearProgress style={{ borderRadius: "5%" }} />
                  <Paper
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#0000",
                      color: "#121212",
                      boxShadow: "0 0 black",
                    }}
                  >
                    {" "}
                    <h6 style={{ marginLeft: "20px" }}>
                      Going to{" "}
                      {this.state.product.id !== undefined
                        ? this.state.product.store_id.store_name
                        : ""}{" "}
                      ...
                    </h6>
                  </Paper>
                  {this.state.product.id !== undefined ? (
                    <LatestProductsListDisplay
                      data={this.state.product}
                      key={this.state.product.id}
                    />
                  ) : (
                    ""
                  )}
                </Paper>
              </div>
              <div className="mdl-cell mdl-cell--4-col-desktop mdl-cell--2-col-tablet mdl-cell--0-col-phone"></div>
            </div>
          </div>
        )}

        <a id="productClickLink" href={this.state.product.product_id}></a>
        {affilate_scripts_adding()}
        <script type="text/javascript">{rakutien()}</script>
        <script type="text/javascript">
          amzn_assoc_ad_type = "link_enhancement_widget"; amzn_assoc_tracking_id
          = "chillaxe-20"; amzn_assoc_linkid =
          "6a92a32a8ec19df8437ad1cea7b7c1ac"; amzn_assoc_placement = "";
          amzn_assoc_marketplace = "amazon"; amzn_assoc_region = "US";
        </script>
        <script src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1&MarketPlace=US"></script>
      </>
    );
  }
}
export default RedirectProduct;
