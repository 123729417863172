import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import MenuItem from "@material-ui/core/MenuItem";
import FlagRoundedIcon from "@material-ui/icons/FlagRounded";
import axios from "axios";

import { connect } from "react-redux";
const options = [
  "Product Not Good",
  "I dont like this product",
  "Adult content",
  "Hateful Product",
  "Product Missleading",
  "Harmful Product",
  "Remove this Product",
];
const styles = {
  reportMenuCssStyle: {
    color: "saddlebrown",
    fontWeight: "600",
    textTransform: "capitalize",
  },
  buyIconButton: {
    marginRight: "5px",
    borderRadius: "3px",
    background: "#ffffff00",
    fontWeight: "700",
    whiteSpace: "nowrap",
    color: "#fff",
  },
  cancelIconButton: {
    marginRight: "5px",
    borderRadius: "3px",
    background: "#ffffff00",
    fontWeight: "700",
    whiteSpace: "nowrap",
    color: "#ea1a1a",
  },
};
function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);
  const updateUserProfile = (value) => {
    const report_content = "NAN";
    const report_option = value;
    const user_report = props.user;
    const product_report = props.product;

    axios.post(`https://api.ajarstore.com/api/productreport/`, {
      report_content,
      report_option,
      user_report,
      product_report,
    });
  };

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    updateUserProfile(value);
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle
        style={{ background: "#121212", color: "#fff" }}
        id="confirmation-dialog-title"
      >
        Report Product
      </DialogTitle>
      <DialogContent
        className="ReportDialogContent"
        style={{ background: "#191919", color: "#fff" }}
      >
        <RadioGroup
          style={{ color: "#fff" }}
          color="#fff"
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              style={{ color: "#fff" }}
              value={option}
              key={option}
              control={<Radio style={{ color: "#ffffff9c" }} />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions style={{ background: "#121212", color: "#fff" }}>
        <Button
          style={styles.cancelIconButton}
          autoFocus
          onClick={handleCancel}
          color="primary"
        >
          Cancel
        </Button>
        <Button style={styles.buyIconButton} onClick={handleOk} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
}));

function ProductReport(props) {
  const funcName = (props) => {};
  funcName.propTypes = {
    state: PropTypes.object.isRequired,
  };
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("Dione");

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  return (
    <>
      <>
        <MenuItem
          button
          aria-haspopup="true"
          aria-controls="ringtone-menu"
          aria-label="phone ringtone"
          onClick={handleClickListItem}
          role="listitem"
          style={styles.reportMenuCssStyle}
        >
          <FlagRoundedIcon style={{ marginRight: "5px" }} />
          Report Product
        </MenuItem>

        <ConfirmationDialogRaw
          classes={{
            paper: classes.paper,
          }}
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          user={props.state.user.id}
          product={props.product.id}
        />
      </>
    </>
  );
}

const mapStateToProp = (state) => {
  return {
    state: state,
  };
};
export default connect(mapStateToProp)(ProductReport);
