import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  error: null,
  messages: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
        error: action.payload,
      };
    case USER_LOADED:
      return {
        ...state,
        token: localStorage.getItem("token"),
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
        error: action.payload,
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
// // import * as actionTypes from "../actions/actionTypes";
// import { USER_LOADED, AUTH_ERROR, USER_LOADING } from "../actions/types";
// // import { updateObject } from "../utility";

// const initialState = {
//   token: localStorage.getItem("token"),
//   isAuthenticated: null,
//   user: null,
//   error: null,
//   loading: false,
// };

// // const authStart = (state, action) => {
// //   return updateObject(state, {
// //     error: null,
// //     loading: true
// //   });
// // };

// // const authSuccess = (state, action) => {
// //   return updateObject(state, {
// //     token: action.token,
// //     error: null,
// //     loading: false
// //   });
// // };

// // const authFail = (state, action) => {
// //   return updateObject(state, {
// //     error: action.error,
// //     loading: false
// //   });
// // };

// // const authLogout = (state, action) => {
// //   return updateObject(state, {
// //     token: null
// //   });
// // };

// // const reducer = (state = initialState, action) => {
// //   switch (action.type) {
// //     // case actionTypes.AUTH_START:
// //     //   return authStart(state, action);
// //     // case actionTypes.AUTH_SUCCESS:
// //     //   return authSuccess(state, action);
// //     // case actionTypes.AUTH_FAIL:
// //     //   return authFail(state, action);
// //     // case actionTypes.AUTH_LOGOUT:
// //     //   return authLogout(state, action);
// //     default:
// //       return state;
// //   }
// // };
// function reducer(state = initialState, action) {
//   switch (action.type) {
//     case USER_LOADING:
//       return {
//         ...state,
//         loading: true,
//       };
//     case USER_LOADED:
//       return {
//         ...state,
//         isAuthenticated: true,
//         loading: false,
//         user: action.payload,
//       };
//     case AUTH_ERROR:
//       localStorage.removeItem("token");
//       return {
//         ...state,
//         token: null,
//         user: null,
//         isAuthenticated: false,
//         loading: false,
//       };
//     default:
//       return state;
//   }
// }

// export default reducer;
