import React from "react";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Alert from "@material-ui/lab/Alert";
import { ReactTinyLink } from "react-tiny-link";
const Styles = {
  UploadBtnCss: {
    margin: "5px 5px 5px 10px",
    backgroundColor: "#F2F2F2",
    textTranform: "capitalize",
    maxWidth: "300px",
    overflow: "hidden",
  },
};
const ProductReview = ({ setForm, formData }) => {
  const {
    name,
    mrp,
    sp,
    details,
    specs,
    keywords,
    imgurl,
    producturl,
    storeurl,
  } = formData;
  var prourl = producturl;
  if (prourl != undefined && prourl.startsWith("http")) {
    prourl = prourl;
  } else {
    prourl = null;
  }
  var storurl = storeurl;
  if (storurl != undefined && storurl.startsWith("http")) {
    storurl = storurl;
  } else {
    storurl = null;
  }

  return (
    <div className="form">
      <Alert
        style={{
          backgroundColor: "#fffee6",
          marginLeft: "10px",
          border: "1px solid #e6e6e6",
          color: "#000126",
          fontWeight: "600",
        }}
        severity="error"
      >
        Adult products are not allowed. If added Your account will be
        Deactivated
      </Alert>
      <h6
        style={{
          margin: "10px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "500",
          color: "#676773",
        }}
      >
        Name of Product
      </h6>
      <h4
        style={{
          margin: "5px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "600",
        }}
      >{`${name}`}</h4>

      <h6
        style={{
          margin: "20px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "500",
          color: "#676773",
        }}
      >
        Mrp of Product
      </h6>
      <h4
        style={{
          margin: "5px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "600",
        }}
      >{`${mrp}`}</h4>

      <h6
        style={{
          margin: "20px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "500",
          color: "#676773",
        }}
      >
        Selling Price of Product
      </h6>
      <h4
        style={{
          margin: "5px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "600",
        }}
      >{`${sp}`}</h4>

      <h6
        style={{
          margin: "20px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "500",
          color: "#676773",
        }}
      >
        Details of Product
      </h6>
      <h4
        style={{
          margin: "5px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "600",
        }}
      >{`${details}`}</h4>
      <h6
        style={{
          margin: "20px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "500",
          color: "#676773",
        }}
      >
        Specifications of Product
      </h6>
      <h4
        style={{
          margin: "5px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "600",
        }}
      >{`${specs}`}</h4>
      <h6
        style={{
          margin: "20px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "500",
          color: "#676773",
        }}
      >
        Keywords of Product
      </h6>
      <h4
        style={{
          margin: "5px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "600",
        }}
      >{`${keywords}`}</h4>
      <h6
        style={{
          margin: "20px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "500",
          color: "#676773",
        }}
      >
        Image of Product
      </h6>
      <img
        src={`${imgurl}`}
        style={{
          height: "300px",
          width: "100%",
          objectFit: "scale-down",
          borderRadius: "5px",
        }}
        alt="Image Url may be Broken"
      />
      <h6
        style={{
          margin: "20px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "500",
          color: "#676773",
        }}
      >
        Product Link
      </h6>
      {prourl != null ? (
        <ReactTinyLink
          cardSize="small"
          showGraphic={true}
          maxLine={2}
          minLine={1}
          url={`${prourl}`}
        />
      ) : (
        <h4
          style={{
            margin: "5px 5px 0px 20px",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Product Url Not Added
        </h4>
      )}
      <h6
        style={{
          margin: "20px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "500",
          color: "#676773",
        }}
      >
        Store Id of Product
      </h6>

      <h4
        style={{
          margin: "5px 5px 0px 20px",
          fontSize: "14px",
          fontWeight: "600",
        }}
      >{`${storeurl}`}</h4>
    </div>
  );
};

export default ProductReview;
