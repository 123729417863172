import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import SettingsApplicationsRoundedIcon from "@material-ui/icons/SettingsApplicationsRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import { Link } from "react-router-dom";
import MainForm from "../AddStuff/ProductHeadForm";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const ButtonCss = {
  color: "#000",
  backgroundColor: "#fff",
  padding: "0px",
  marginTop: "6px",
};
const Styles = {
  menuItemCss: {
    fontWeight: "600",
    textTransform: "capitalize",
    color: "#000",
  },
};
const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "#0067e2",
    fontWeight: 600,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip classes={classes} {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export default function MenuListComposition(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const logoutmaster = () => {
    props.logout();
    handleClose();
  };

  const ProfileId = props.profile.user_uuid;
  var profile_icon_img = `https://ajpxzukkep.cloudimg.io/v7/${props.profile.image}?width=32&height=32&func=fit`;
  return (
    <div className={classes.root}>
      <div>
        <BootstrapTooltip
          style={{ backgroundColor: "black" }}
          title={`Ajarstore Account: ${props.profile.first_name}`}
        >
          <IconButton
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            style={ButtonCss}
          >
            <Avatar
              alt={props.profile.first_name}
              src={profile_icon_img}
              className={classes.small}
            />
          </IconButton>
        </BootstrapTooltip>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "right top" : "right bottom",
              }}
            >
              <Paper style={{ backgroundColor: "#fff" }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    style={{ backgroundColor: "#fff" }}
                  >
                    <MenuItem style={Styles.menuItemCss} onClick={handleClose}>
                      Hi {props.profile.first_name} !
                    </MenuItem>
                    {ProfileId !== undefined ? (
                      <Link to={`/user/${ProfileId}/profile`}>
                        <MenuItem
                          style={Styles.menuItemCss}
                          onClick={handleClose}
                        >
                          <AccountCircleRoundedIcon /> | Profile
                        </MenuItem>
                      </Link>
                    ) : (
                      <Link to={`/login`}>
                        <MenuItem
                          style={Styles.menuItemCss}
                          onClick={handleClose}
                        >
                          login
                        </MenuItem>
                      </Link>
                    )}
                    <Link to={`/user/${ProfileId}/edit`}>
                      <MenuItem
                        style={Styles.menuItemCss}
                        onClick={handleClose}
                      >
                        <SettingsApplicationsRoundedIcon /> | Setting
                      </MenuItem>
                    </Link>
                    <MainForm />
                    <a onClick={logoutmaster} href="">
                      <MenuItem
                        style={Styles.menuItemCss}
                        onClick={logoutmaster}
                        style={{
                          backgroundColor: "#fff",
                          borderTop: "1px dashed grey",
                          marginTop: "5px",
                          color: "#000",
                          fontWeight: "700",
                        }}
                      >
                        <ExitToAppRoundedIcon /> | Logout
                      </MenuItem>
                    </a>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
