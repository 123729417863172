import React, { Component, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import store from "./store/store";
import { loadUser } from "./store/actions/auth";
import history from "./history";
import Header from "./components/Layout/Header";
import RedirectProduct from "./components/common/RedirectProduct";

// import TestBtn from "./components/TestBtn";
const BaseRouter = React.lazy(() => import("./components/Routes"));

// const Hist = createBrowserHistory();

class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    // history={history}

    return (
      <>
        <Router history={history}>
          <Header />
          <main className="mdl-layout__content">
            <div className="page-content">
              <Suspense fallback={<div></div>}>
                <BaseRouter />
              </Suspense>
            </div>
          </main>
        </Router>
      </>
    );
  }
}

export default App;
