import React from "react";
import TextField from "@material-ui/core/TextField";
const ItemForm = ({ label, children, type = "text", ...otherProps }) => (
  <div style={{ width: "100%", float: "left" }}>
    <div style={{ width: "90%", float: "left" }}>
      <TextField
        className="addtextfield"
        size="small"
        style={{ margin: 8 }}
        label={` ${label}`}
        margin="normal"
        variant="outlined"
        type={type}
        {...otherProps}
      ></TextField>
    </div>
  </div>
);

export default ItemForm;
