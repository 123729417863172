import React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
const PriceForm = ({ label, children, type = "text", ...otherProps }) => (
  <div style={{ width: "44%", marginRight: "2%", float: "left" }}>
    <div style={{ width: "100%", float: "left" }}>
      <TextField
        className="addtextfield"
        size="small"
        style={{ margin: 8 }}
        label={` ${label}`}
        margin="normal"
        variant="outlined"
        type={type}
        {...otherProps}
        InputProps={{
          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
        }}
      ></TextField>
    </div>
  </div>
);

export default PriceForm;
