import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
const Style = {
  searchBtnCss: {
    color: "#121212",
    float: "left",
    border: "0px",
    backgroundColor: "burlywood",
    borderLeft: "0px",
    borderRadius: "0px 5px 5px 0px",
    height: "36px",
    margin: "8px 0px",
  },
  searchIconCss: {
    color: "#121212",
    fontSize: "26px",
  },
};
class SearchFunc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      firesearh: false,
      result: [],
      error: false,
      loading: false,
      hasMore: true,
      offset: 0,
      limit: 20,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { search } = this.state;
    this.setState({
      firesearh: true,
      result: [],
      loading: false,
    });
    const test = search.replace(/\s+/g, "-");

    // window.location.href = `http://localhost:3000/search/${test}`;
  };

  onChange = (e) => {
    this.setState({ firesearh: false, [e.target.name]: e.target.value });
  };

  render() {
    const { search } = this.state;
    const test = search.replace(/\s+/g, "-");
    return (
      <>
        <div className="mdl-grid search-holding-div-form">
          <form onSubmit={this.onSubmit}>
            <div
              className="mdl-cell mdl-cell--10-col"
              style={{ float: "left", margin: "0px" }}
            >
              {" "}
              <input
                type="text"
                name="search"
                className="textbox"
                placeholder="Search"
                autoComplete="off"
                onChange={this.onChange}
                value={search}
              />
            </div>
            <div
              className="SeachButton_top mdl-cell mdl-cell--2-col"
              style={{ float: "left", margin: "0px" }}
            >
              {" "}
              <Button onClick={this.onSubmit} style={Style.searchBtnCss}>
                <SearchIcon style={Style.searchIconCss} />
              </Button>
            </div>
          </form>
        </div>

        {this.state.firesearh ? (
          <Redirect
            to={{
              pathname: `/search/${test}`,
              state: { result: this.state.result },
            }}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}
export default SearchFunc;
