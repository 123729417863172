import React, { Component } from "react";
import { Link } from "react-router-dom";
import StorePopper from "./StorePopper";
class StoresData extends Component {
  render() {
    // const { id, store_unqiue_id, store_name } = this.props.productId;
    return (
      <StorePopper
        productId={this.props.productId}
        timePosted={this.props.timePosted}
      />
    );
  }
}
export default StoresData;
