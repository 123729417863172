import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Paper from "@material-ui/core/Paper";
import { useForm } from "react-hooks-helper";
import ProductStep1 from "./ProductStep1";
import ProductStep2 from "./ProductStep2";
import ProductReview from "./ProductReview";
import MenuItem from "@material-ui/core/MenuItem";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import axios from "axios";
const Styles = {
  menuItemCss: {
    fontWeight: "600",
    textTransform: "capitalize",
    color: "#000",
    backgroundColor: "#fff",
    // borderRadius: "5px",
    // margin: "0px 10px",
  },
  BtnOpen: {
    backgroundColor: "white",
    color: "black",
    border: "1px dashed white",
  },
  DialogCss: {},
  DialogContentCss: {
    overflowX: "hidden",
    overflowY: "auto",
  },
  DialogImgCss: {
    width: "100%",
    height: "400px",
    objectFit: "cover",
    overflow: "hidden",
    borderRadius: "5px",
  },
  DialogSmallHeadingCss: {
    fontSize: "20px",
    fontWeight: "bold",
    textTranform: "capitalize",
    margin: "5px 5px 10px 30px",
  },
  mainstylecss: {
    marginTop: "70px",
    width: "100%",
    marginLeft: "78px",
  },
  TopTabContainer: {
    height: "56px",
    borderBottom: "1px solid #e0e0e0",
  },
  SideSpaceCss: {
    height: "100%",
    borderRight: "1px solid #e0e0e0",
    marginTop: "5px",
  },
  btnCss1: {
    margin: "10px",
    marginLeft: "30px",
    background: "#E5F1FF",
    height: "32px",
    color: "#0067E2",
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "14px",
  },
  btnCss2: {
    margin: "10px",
    marginLeft: "10px",
    height: "32px",
    color: "#000",
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "14px",
  },
  btnCss3: {
    margin: "10px",
    marginLeft: "30px",
    height: "32px",
    color: "black",
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "14px",
    border: "1px solid #d2d2d2",
  },
  btnCss4: {
    margin: "10px",
    marginLeft: "10px",
    background: "#0067E2",
    height: "32px",
    color: "#fff",
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "14px",
  },
  ItemOuterGridCss: {
    padding: "20px 20px 20px 20px",
  },

  ItemPaperCss: {
    height: "358px",
  },
  ImgCss: {
    width: "100%",
    height: "260px",
    objectFit: "cover",
    borderRadius: "5px 5px 0px 0px",
  },
  TextCss: {
    fontSize: "14px",
    maxHeight: "45px",
    margin: "5px 5px 3px 10px",
    LineHeight: "20px",
    fontWeight: "600",
    overflow: "hidden",
  },
  SmallTextCss: {
    fontSize: "12px",
    LineHeightn: "17px",
    maxHeight: "20px",
    margin: "10px 5px 3px 10px",
    color: "#676773",
  },
};
function getSteps() {
  return [0, 1, 2];
}

function getStepContent(stepIndex, props) {
  switch (stepIndex) {
    case 0:
      return <ProductStep1 {...props} />;
    case 1:
      return <ProductStep2 {...props} />;
    case 2:
      return <ProductReview {...props} />;
    default:
      return "You Have Lost";
  }
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const defaultData = {
  name: null,
  mrp: null,
  sp: null,
  details: null,
  specs: null,
  keywords: null,
  imgurl: null,
  producturl: null,
  storeurl: null,
};
export default function MainForm() {
  const [formData, setForm] = useForm(defaultData);
  const props = { formData, setForm };
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const AddProduct = () => {
    // const profile_id = this.props.match.params.UserId;
    const {
      name,
      mrp,
      sp,
      details,
      specs,
      keywords,
      imgurl,
      storeurl,
      producturl,
    } = formData;
    var stourl;
    if (formData.storeurl != null && formData.storeurl != undefined) {
      stourl = formData.storeurl;
    } else {
      stourl = 20;
    }
    // const token = this.props.state.token;
    // axios.defaults.headers = {
    //   "Content-Type": "application/json",
    //   Authorization: `Token ${token}`,
    // };
    axios.post(`https://api.ajarstore.com/api/product/add/`, {
      product_name: name,
      product_mrp: mrp,
      product_price: sp,
      product_description: details,
      product_keywords: specs,
      product_catlog: keywords,
      product_image: imgurl,
      store_id: stourl,
      product_id: producturl,
    });
  };

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAdd = () => {
    handleClose();
    AddProduct();
  };
  return (
    <>
      <MenuItem style={Styles.menuItemCss} onClick={handleClickOpen}>
        <NoteAddIcon /> | Add Product
      </MenuItem>
      <Dialog
        style={Styles.DialogCss}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <h6
            style={{
              fontWeight: "bold",
              textTranform: "capitalize",
              margin: "0px",
              fontSize: "1em",
            }}
          >
            Product Details
          </h6>
        </DialogTitle>
        <DialogContent style={Styles.DialogContentCss} dividers>
          {getStepContent(activeStep, props)}{" "}
        </DialogContent>
        <DialogActions>
          <div>
            <Typography className={classes.instructions}></Typography>
            <div>
              <Button
                style={{ border: "1px solid #d6d6d6" }}
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button
                color="primary"
                style={
                  activeStep === steps.length - 1
                    ? { backgroundColor: "#0067E2", color: "white" }
                    : { backgroundColor: "#d8a35f", color: "black" }
                }
                onClick={
                  activeStep === steps.length - 1 ? handleAdd : handleNext
                }
              >
                {activeStep === steps.length - 1 ? "ADD PRODUCT" : "Next"}
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
