import React from "react";
import TextField from "@material-ui/core/TextField";
const DescribeForm = ({
  label,
  rows,
  children,
  type = "text",
  ...otherProps
}) => (
  <div style={{ width: "100%", float: "left" }}>
    <div style={{ width: "90%", float: "left" }}>
      <TextField
        className="addtextfield"
        size="small"
        style={{ margin: 8 }}
        label={` ${label}`}
        margin="normal"
        variant="outlined"
        multiline
        rows={`${rows}`}
        type={type}
        {...otherProps}
      ></TextField>
    </div>
  </div>
);

export default DescribeForm;
