import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ItemForm from "./ItemForm";
import DescribeForm from "./DescribeForm";
import PriceForm from "./PriceForm";
import Button from "@material-ui/core/Button";
const Styles = {
  UploadBtnCss: {
    margin: "5px 5px 5px 10px",
    border: "1px dashed grey",
    maxWidth: "300px",
    textTranform: "capitalize",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  input: {
    display: "none",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));
const ProductStep1 = ({ setForm, formData }) => {
  const classes = useStyles();

  const { name, mrp, sp, details, specs, keywords } = formData;
  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        {/* <h6
          style={{
            margin: "5px 5px 0px 10px",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Assign Quantity*
        </h6> */}
        <ItemForm name="name" label="Name" value={name} onChange={setForm} />
        <div style={{ width: "100%", float: "left" }}>
          <PriceForm
            name="mrp"
            label="Mrp Price"
            value={mrp}
            onChange={setForm}
          />
          <PriceForm
            name="sp"
            label="Selling Price"
            value={sp}
            onChange={setForm}
          />
        </div>
        <DescribeForm
          name="details"
          label="Detials"
          value={details}
          rows="6"
          onChange={setForm}
        />
        <DescribeForm
          name="specs"
          label="Specifications"
          value={specs}
          rows="6"
          onChange={setForm}
        />
        <DescribeForm
          name="keywords"
          label="KeyWords"
          helperText="Enter Keywords seperated by comma"
          value={keywords}
          rows="2"
          onChange={setForm}
        />
      </form>
    </>
  );
};

export default ProductStep1;
