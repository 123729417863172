import React, { Component, Fragment } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import { logout } from "../../store/actions/auth";
import PropTypes from "prop-types";
import UserInfo from "./UserInfo";
import axios from "axios";
import Search from "./Search";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import * as actions from "../../store/actions/auth";
import Alerts from "./Alerts";
import Fab from "@material-ui/core/Fab";

const Style = {
  searchBtnCss: {
    color: "#fff",
    float: "left",
    border: "1px solid #252525",
    backgroundColor: "#101010",
    borderLeft: "1px solid #1b1a1a",
    borderRadius: "0px 5px 5px 0px",
    height: "36px",
    margin: "8px 0px",
  },
  searchIconCss: {
    color: "#fff",
  },
  loginBtn: {
    color: "rgb(253 253 253)",
    padding: "10px",
    fontWeight: "700",
    textTransform: "uppercase",
    fontSize: "12px",
    marginTop: "5px",
    backgroundColor: "burlywood",
  },
};
// const SearchReturn = (test) => {
//   console.log("search .. fired");
// };
class Header extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired,
  };
  state = {
    user: [],
    profile: [],
    search: "",
    firesearh: false,
  };

  UserProfileData = (id) => {
    const user = id;
    axios.get(`https://api.ajarstore.com/api/up/?user=${user}`).then((res) => {
      this.setState({
        profile: res.data,
        user: res.data.user,
      });
    });
  };

  loadFunc = (id) => {
    this.UserProfileData(id);
  };
  // search function
  onSubmit = (e) => {
    e.preventDefault();
    const { search } = this.state;
    this.setState({
      firesearh: true,
    });
    const test = search.replace(/\s+/g, "-");
    window.location.href = `https://api.ajarstore.com/search/${test}`;
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { search } = this.state;
    const test = search.replace(/\s+/g, "-");
    return (
      <Fragment>
        <header className="mdl-layout__header mdl-layout__header--waterfall">
          <div className="mdl-layout__header-row top-header-div">
            <Link to="/" className="mdl-layout-title">
              <img src="https://res.cloudinary.com/ajarshopinsta/image/upload/v1598773234/bag_1_xtvkmm.webp" />
            </Link>
            <Link to="/" className="mdl-layout-title-mble">
              <img src="https://res.cloudinary.com/ajarshopinsta/image/upload/v1598773234/bag_1_xtvkmm.webp" />
            </Link>
            <div className="mdl-grid top_search_fied_holder">
              <div className="mdl-cell mdl-cell--6-col mdl-cell--6-col-tablet mdl-cell--3-col-phone top_search_field">
                <Search />
              </div>
              <div className="mdl-cell mdl-cell--2-col search_field_adjuster"></div>

              <div className="mdl-cell mdl-cell--2-col top_header_user_content">
                {this.props.state.isAuthenticated &&
                this.state.profile.length === 0 ? (
                  this.loadFunc(this.props.state.user.id)
                ) : (
                  <></>
                )}

                {this.props.state.isAuthenticated ? (
                  <UserInfo
                    style={{ marginTop: "6px" }}
                    profile={
                      this.state.profile.length > 0
                        ? this.state.profile[0]
                        : this.state.profile
                    }
                    logout={this.props.logout}
                  />
                ) : (
                  <>
                    <Link to="/login" className="SigninBtnHeader">
                      <Button style={Style.loginBtn}>
                        <AccountCircleIcon
                          style={{ color: "#fff", marginRight: "5px" }}
                        />
                        Sign In
                      </Button>
                    </Link>
                    <Link to="/login" className="signiniconheader">
                      <AccountCircleIcon
                        style={{
                          color: "burlywood",
                          marginRight: "5px",
                          marginTop: "10px",
                          fontSize: "26px",
                        }}
                      />
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mdl-layout__header-row bottom-header-div">
            <nav className="mdl-navigation">
              <Link className="mdl-navigation__link" to="/">
                Home
              </Link>
              <Link className="mdl-navigation__link" to="/explore">
                explore
              </Link>
              <Link className="mdl-navigation__link" to="/catlog">
                catlog
              </Link>
              <Link className="mdl-navigation__link" to="/stores/all">
                stores
              </Link>
            </nav>
          </div>
        </header>
        {/* <Fab variant="extended" size="small" color="primary" aria-label="add">
          add
        </Fab> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state,
});

export default connect(mapStateToProps, { logout })(Header);
