import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FavoriteIcon from "@material-ui/icons/Favorite";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import TelegramIcon from "@material-ui/icons/Telegram";
import FlagRoundedIcon from "@material-ui/icons/FlagRounded";
import FavoriteBorderRoundedIcon from "@material-ui/icons/FavoriteBorderRounded";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import PinterestIcon from "@material-ui/icons/Pinterest";
import InstagramIcon from "@material-ui/icons/Instagram";
const Styles = {
  arrowCss: {
    marginLeft: "10px",
    fontSize: "30px",
  },
  actionconButtons: {
    color: "#61310f",
    backgroundColor: "#00000000",
    marginTop: "0px",
    marginRight: "0px",
    fontWeight: "700",
    border: "0px",
  },
  actionShareButon: {
    minWidth: "22px",
    padding: " 3px 9px",
    color: "#121212",
    backgroundColor: "#00000000",
    marginTop: "0px",
    marginRight: "0px",
    fontWeight: "600",
  },
  closeButtons: {
    border: "1px solid #2b2a2a",
    fontWeight: "700",
    color: "#b94040",
    textTransform: "capitalize",
  },
  buyIconButton: {
    marginRight: "5px",
    borderRadius: "3px",
    background: "saddlebrown",
    boxShadow: "0 0 black",
    color: "white",
    fontWeight: "700",
    whiteSpace: "nowrap",
    fontFamily: "inherit",
  },
  btnGrupCss: {
    marginLeft: "0px",
    padding: "5px 10px",
    backgroundColor: "#00000000",
  },
  likeOnChange: {
    color: "#f32424",
    backgroundColor: "#00000000",
    marginTop: "0px",
    marginRight: "0px",
    fontWeight: "600",
    border: "0px",
  },
  saveOnChange: {
    color: "green",
    backgroundColor: "#00000000",
    marginTop: "0px",
    marginRight: "0px",
    fontWeight: "600",
    border: "0px",
  },
  shareDialog: {},
  shareDialogTitle: {
    backgroundColor: "#191919",
    color: "#fff",
  },
  searchBtnCss: {
    color: "#fff",
    float: "left",
    border: "1px solid #252525",
    backgroundColor: "#101010",
    borderLeft: "1px solid #1b1a1a",
    borderRadius: "0px 5px 5px 0px",
    height: "36px",
    margin: "8px 0px",
  },
  searchIconCss: {
    color: "#fff",
  },
  shareBtnSocialBtn: {
    width: "100%",
    border: " 1px solid grey",
    color: "#000000",
    backgroundColor: "white",
    textTransform: "capitalize",
    fontWeight: "700",
  },
};

function ShareDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const sharefbimage = () => {
  //   FB.init({ status: true, cookie: true });
  //   FB.ui(
  //     {
  //       method: `share`,
  //       name: "Facebook Dialogs",
  //       href: $(location).attr("href"),
  //       link: "https://developers.facebook.com/docs/dialogs/",
  //       picture: "your image url",
  //       caption: "Ishelf Book",
  //       description: "your description",
  //     },
  //     function (response) {
  //       if (response && response.post_id) {
  //         alert("success");
  //       } else {
  //         alert("error");
  //       }
  //     }
  //   );
  // };
  var product_image = "";
  var product_name = "";
  var id = "";
  if (props.product != undefined) {
    product_image = props.product.product_image;
    product_name = props.product.product_name;
    id = props.product.id;
  }

  return (
    <>
      <Tooltip arrow title="Share" placement="top">
        <Button style={Styles.actionShareButon} onClick={handleClickOpen}>
          <TelegramIcon />
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={Styles.shareDialog}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogTitle style={Styles.shareDialogTitle} id="alert-dialog-title">
          {"Share"}
        </DialogTitle>
        <DialogContent style={Styles.shareDialogTitle}>
          <DialogContentText
            id="alert-dialog-description"
            style={Styles.shareDialogTitle}
          >
            <div className="mdl-grid">
              <div className="mdl-cell mdl-cell--3-col mdl-cell--2-col-tablet mdl-cell--12-col-phone">
                <img
                  style={{ width: "100%", height: "100%", borderRadius: "5px" }}
                  src={product_image}
                  alt={product_name}
                />
              </div>
              <div className="mdl-cell mdl-cell--8-col mdl-cell--4-col-tablet mdl-cell--4-col-phone">
                {product_name}
              </div>
            </div>
            <div className="mdl-grid">
              <div className="mdl-cell mdl-cell--3-col mdl-cell--4-col-tablet mdl-cell--5-col-phone">
                <Button
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                    `https://ajarstore.com/item/${id}`
                  )}`}
                  target="_blank"
                  style={Styles.shareBtnSocialBtn}
                >
                  <FacebookIcon
                    style={{
                      color: " #3b5998",
                      fontSize: "16px",
                      marginRight: "8px",
                    }}
                  />
                  Facebook
                </Button>
              </div>
              <div className="mdl-cell mdl-cell--3-col mdl-cell--4-col-tablet mdl-cell--5-col-phone">
                <Button
                  target="_blank"
                  href={`http://twitter.com/share?card=photo&image=${encodeURI(
                    product_image
                  )}&url=${encodeURI(
                    `https://ajarstore.com/item/${id}`
                  )}&title=${encodeURIComponent(
                    product_name
                  )}&description=${encodeURIComponent(
                    props.product.product_description
                  )}`}
                  style={Styles.shareBtnSocialBtn}
                >
                  <TwitterIcon
                    style={{
                      color: " #00acee",
                      fontSize: "16px",
                      marginRight: "8px",
                    }}
                  />
                  Twitter
                </Button>
              </div>
              <div className="mdl-cell mdl-cell--3-col mdl-cell--4-col-tablet mdl-cell--5-col-phone">
                <Button
                  href={`http://pinterest.com/pin/create/button/?url=${encodeURI(
                    `https://ajarstore.com/item/${id}`
                  )}&media=${encodeURIComponent(
                    product_image
                  )}&description=${encodeURIComponent(
                    product_name + props.product.product_description
                  )}`}
                  style={Styles.shareBtnSocialBtn}
                  target="_blank"
                >
                  <PinterestIcon
                    style={{
                      color: " #E60023",
                      fontSize: "16px",
                      marginRight: "8px",
                    }}
                  />
                  Pinterest
                </Button>
              </div>
              {/* <div className="mdl-cell mdl-cell--3-col mdl-cell--4-col-tablet mdl-cell--5-col-phone">
                <Button style={Styles.shareBtnSocialBtn}>
                  <InstagramIcon
                    style={{
                      color: "#d60000",
                      fontSize: "16px",
                      marginRight: "8px",
                    }}
                  />
                  Instagram
                </Button>
              </div> */}
              <div className="mdl-cell mdl-cell--11-col ">
                {" "}
                <form>
                  <input
                    type="text"
                    className="textbox"
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                    value={`https://ajarstore.com/item/${id}`}
                  />
                </form>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={Styles.shareDialogTitle}>
          <Button onClick={handleClose} style={Styles.closeButtons}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const PriceButtonCss = {
  backgroundColor: "#1b1a1a",
  border: "1px dashed grey",
  color: "#fff",
  fontWeight: "800",
  margin: "10px 0px",
};
class ActionsOfPage extends Component {
  state = {
    product: "",
    isLiked: false,
    like: [],
    isSaved: false,
    save: [],
    product_link: "",
    productContent: [],
  };
  static propTypes = {
    state: PropTypes.object.isRequired,
  };
  // pre fetch like data
  getPreDataOnLike = () => {
    if (this.props.state.isAuthenticated === true) {
      const token = this.props.state.token;
      const user_id = this.props.state.user.id;
      const id = this.props.productId;
      // if (user_id !== null) {
      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          `https://api.ajarstore.com/api/likeUser/?user_id=${user_id}&product_id=${id}`
        )
        .then((res) => {
          const like = res.data;
          this.setState({
            like: like,
          });
          this.UpdateInitalLikeComponent();
        });
    } else {
      return;
    }
  };
  // updateing like initially
  UpdateInitalLikeComponent = () => {
    if (this.state.like.length > 0) {
      const { product_like_uuid } = this.state.like[0];
      if (product_like_uuid !== undefined) {
        this.setState({ isLiked: true });
      }
    }
  };
  // liking a post
  setLike = () => {
    const ProductId = this.state.product;
    const userId = this.props.state.user.id;

    const token = this.props.state.token;
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .post("https://api.ajarstore.com/api/i/like/", {
        user_liked: userId,
        product_associated: ProductId,
      })
      .then(() => {
        this.setState({ isLiked: true });
        this.handleLoad();
      });
  };

  deleteMultipleLikes = () => {
    for (var i = 0; i < this.state.like.length; i++) {
      const { product_like_uuid } = this.state.like[i];
      const token = this.props.state.token;
      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      axios.delete(
        `https://api.ajarstore.com/api/i/like/${product_like_uuid}/`
      );
      this.setState({ like: [] });
    }
  };
  handleLoad() {
    this.getPreDataOnLike();
  }
  handleLikechange = (e) => {
    if (this.props.state.isAuthenticated === true) {
      if (this.state.isLiked === false && this.state.like.length < 1) {
        this.setLike();
      } else {
        this.deleteMultipleLikes();
        this.setState({ isLiked: false });
      }
    } else {
      console.log("login 1st");
    }
  };
  // save component
  // saved products logics start here
  getPreDataOnSave = () => {
    if (this.props.state.isAuthenticated === true) {
      const token = this.props.state.token;
      const user_id = this.props.state.user.id;
      const id = this.props.productId;
      // if (user_id !== null) {
      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      axios
        .get(
          `https://api.ajarstore.com/api/usersavedproducts/?user_id=${user_id}&product_id=${id}`
        )
        .then((res) => {
          const save = res.data;
          this.setState({
            save: save,
          });
          this.UpdateInitalSaveComponent();
        });
    } else {
      return;
    }
  };
  // updating save initially
  UpdateInitalSaveComponent = () => {
    if (this.state.save.length > 0) {
      const { product_save_uuid } = this.state.save[0];
      if (product_save_uuid !== undefined) {
        this.setState({ isSaved: true });
      }
    }
  };
  // saving a post

  setSave = () => {
    const id = this.state.product;
    const userId = this.props.state.user.id;
    const token = this.props.state.token;
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios
      .post("https://api.ajarstore.com/api/i/save/", {
        user_saved: userId,
        product_saved: id,
      })
      .then(() => {
        this.setState({ isSaved: true });
        this.handleSaveLoad();
      });
  };
  deleteMultipleSaves = () => {
    for (var i = 0; i < this.state.save.length; i++) {
      const { product_save_uuid } = this.state.save[i];
      const token = this.props.state.token;
      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      axios.delete(
        `https://api.ajarstore.com/api/i/save/${product_save_uuid}/`
      );
      this.setState({ save: [] });
    }
  };
  handleSaveLoad() {
    this.getPreDataOnSave();
  }
  handleSave = (e) => {
    if (this.props.state.isAuthenticated === true) {
      if (this.state.isSaved === false && this.state.save.length < 1) {
        this.setSave();
      } else {
        this.deleteMultipleSaves();
        this.setState({ isSaved: false });
      }
    } else {
      console.log("login 1st");
    }
  };

  componentDidMount() {
    this.setState({
      product: this.props.productId,
      product_link: this.props.ProductLink,
      productContent: this.props.productContent,
    });
    this.getPreDataOnLike();
    this.getPreDataOnSave();
  }
  render() {
    return (
      <Fragment>
        {" "}
        <ButtonGroup
          style={Styles.btnGrupCss}
          size="small"
          aria-label="small outlined button group"
        >
          <Button
            variant="contained"
            size="small"
            className="btn"
            startIcon={<ShoppingCartIcon />}
            href={`https://ajarstore.com/redirect/${this.state.product}/${this.state.product_link}/`}
            style={Styles.buyIconButton}
            target="_blank"
          >
            BUY NOW
          </Button>
          <Tooltip arrow title="Like" placement="top">
            <Button
              onClick={(e) => {
                this.handleLikechange();
              }}
              style={
                this.state.isLiked
                  ? Styles.likeOnChange
                  : Styles.actionconButtons
              }
            >
              {this.state.isLiked ? (
                <FavoriteIcon />
              ) : (
                <FavoriteBorderRoundedIcon />
              )}
            </Button>
          </Tooltip>
          <Tooltip arrow title="Save" placement="top">
            <Button
              onClick={(e) => {
                this.handleSave();
              }}
              style={
                this.state.isSaved
                  ? Styles.saveOnChange
                  : Styles.actionconButtons
              }
            >
              {this.state.isSaved ? (
                <BookmarkRoundedIcon style={{ color: "green" }} />
              ) : (
                <BookmarkBorderIcon />
              )}
            </Button>
          </Tooltip>

          {/* <ShareDialog product={this.state.productContent} /> */}
        </ButtonGroup>
      </Fragment>
    );
  }
}
const mapStateToProp = (state) => {
  return {
    state: state,
  };
};
export default connect(mapStateToProp)(ActionsOfPage);
