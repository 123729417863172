// knox auth system
import axios from "axios";
import { returnErrors } from "./messages";

import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from "./types";
// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
  // User Loading
  dispatch({ type: USER_LOADING });

  axios
    .get("https://api.ajarstore.com/api/user/data/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: AUTH_ERROR,
        payload: [err.response.status, err.response.data],
      });
    });
};
// LOGIN USER
export const login = (username, password) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ username, password });

  axios
    .post("https://api.ajarstore.com/api/user/login/", body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: LOGIN_FAIL,
        payload: [err.response.status, err.response.data],
      });
    });
};

// REGISTER USER
export const register = ({ username, password, email }) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ username, email, password });

  axios
    .post("https://api.ajarstore.com/api/auth/register/", body, config)
    .then((res) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));

      dispatch({
        type: REGISTER_FAIL,
        payload: [err.response.status, err.response.data],
      });
    });
};

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
  axios
    .post(
      "https://api.ajarstore.com/api/user/logout",
      null,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        payload: [err.response.status, err.response.data],
      });
    });
};

// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get token from state
  const token = localStorage.getItem("token");

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

// import axios from "axios";
// import store from "../store";
// // import * as actionTypes from "./actionTypes";
// import { USER_LOADED, USER_LOADING, AUTH_ERROR } from "../actions/actionTypes";
// // knox system | ...05/04/2020...createdAt...
// export const loadUser = () => (dispatch, getState) => {
//   dispatch({ type: USER_LOADING });
//   // get token

//   axios.get("api/user/data/", tokenConfig(getState)).then((res) => {
//     dispatch({
//       type: USER_LOADED,
//       payload: res.data,
//     }).catch((err) => {
//       dispatch({
//         type: AUTH_ERROR,
//       });
//     });
//   });
// };

// // rest-auth system ...not working properly...ufffff....
// // export const authStart = () => {
// //   return {
// //     type: actionTypes.AUTH_START
// //   };
// // };

// // export const authSuccess = token => {
// //   return {
// //     type: actionTypes.AUTH_SUCCESS,
// //     token: token
// //   };
// // };

// // export const authFail = error => {
// //   return {
// //     type: actionTypes.AUTH_FAIL,
// //     error: error
// //   };
// // };
// // export const logout = () => {
// //   localStorage.removeItem("user");
// //   localStorage.removeItem("expirationDate");
// //   return {
// //     type: actionTypes.AUTH_LOGOUT
// //   };
// // };
// // export const checkAuthTimeout = expirationTime => {
// //   return dispatch => {
// //     setTimeout(() => {
// //       dispatch(logout());
// //     }, expirationTime * 1000);
// //   };
// // };

// // export const authLogin = (username, password) => {
// //   return dispatch => {
// //     dispatch(authStart());
// //     axios
// //       .post("http://127.0.0.1:8000/rest-auth/login/", {
// //         username: username,
// //         password: password
// //       })
// //       .then(
// //         res => {
// //           const token = res.data.key;
// //           const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
// //           localStorage.setItem("token", token);
// //           localStorage.setItem("expirationDate", expirationDate);
// //           dispatch(authSuccess(token));
// //           dispatch(checkAuthTimeout(3600));
// //         },
// //         err => {
// //           dispatch(authFail(err));
// //         }
// //       );
// //   };
// // };

// // export const authSignup = (username, email, password1, password2) => {
// //   return dispatch => {
// //     dispatch(authStart());
// //     axios
// //       .post("http://127.0.0.1:8000/rest-auth/registration/", {
// //         username: username,
// //         email: email,
// //         password1: password1,
// //         password2: password2
// //       })
// //       .then(
// //         res => {
// //           const token = res.data.key;
// //           const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
// //           localStorage.setItem("token", token);
// //           localStorage.setItem("expirationDate", expirationDate);
// //           dispatch(authSuccess(token));
// //           dispatch(checkAuthTimeout(3600));
// //         },
// //         err => {
// //           dispatch(authFail(err));
// //         }
// //       );
// //   };
// // };

// // export const authCheckState = () => {
// //   return dispatch => {
// //     const token = localStorage.getItem("token");
// //     if (token === undefined) {
// //       dispatch(logout());
// //     } else {
// //       const expirationDate = new Date(localStorage.getItem("expirationDate"));
// //       if (expirationDate <= new Date()) {
// //         dispatch(logout());
// //       } else {
// //         dispatch(authSuccess(token));
// //         dispatch(
// //           checkAuthTimeout(
// //             (expirationDate.getTime() - new Date().getTime()) / 1000
// //           )
// //         );
// //       }
// //     }
// //   };
// // };

// export const tokenConfig = (getState) => {
//   // Get token from state
//   const token = getState().auth.tokens;

//   // Headers
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };

//   // If token, add to headers config
//   if (token) {
//     config.headers["Authorization"] = `Token ${token}`;
//   }

//   return config;
// };
