import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import WhatshotIcon from "@material-ui/icons/Whatshot";

const PriceStrikeCss = {
  backgroundColor: "#00000000",
  color: "#d65151",
  fontWeight: "700",
  margin: "0px",
  padding: "1px 8px",
  textDecoration: "line-through",
  borderRadius: "20px",
  boxShadow: "0 0 black",
};
const PriceButtonCss = {
  backgroundColor: "#cef3ce",
  color: "#0fa00f",
  fontWeight: "700",
  margin: "8px 0px",
  padding: "1px 8px",
  borderRadius: "20px",
  boxShadow: "0 0 black",
};
function PriceBlock(product_price, product_price_2, product_mrp) {
  if (
    product_price == "" ||
    product_price == " " ||
    product_price == null ||
    product_price == undefined ||
    product_price == "0" ||
    product_price == "0.0" ||
    product_price == "nan" ||
    product_price == "NaN" ||
    product_price == "NAN"
  ) {
    product_price = "";
  }
  if (
    product_price_2 == "" ||
    product_price_2 == " " ||
    product_price_2 == null ||
    product_price_2 == undefined ||
    product_price_2 == "0" ||
    product_price_2 == "0.0" ||
    product_price_2 == "nan" ||
    product_price_2 == "NaN" ||
    product_price_2 == "NAN"
  ) {
    product_price_2 = "";
  }
  if (
    product_mrp == "" ||
    product_mrp == " " ||
    product_mrp == null ||
    product_mrp == undefined ||
    product_mrp == "0" ||
    product_mrp == "0.0" ||
    product_mrp == "nan" ||
    product_mrp == "NaN" ||
    product_mrp == "NAN"
  ) {
    product_mrp = "";
  }
  if (product_price == "" && product_price_2 == "" && product_mrp == "") {
    return (
      <Button
        variant="contained"
        size="small"
        className="gcip"
        style={PriceButtonCss}
      >
        <WhatshotIcon style={{ color: "red" }} /> Get Deal
      </Button>
    );
  } else if (
    product_price != "" &&
    product_price_2 == "" &&
    product_mrp == ""
  ) {
    return (
      <Button
        variant="contained"
        size="small"
        className="gcip"
        style={PriceButtonCss}
      >
        ₹ {product_price}
      </Button>
    );
  } else if (
    product_price == "" &&
    product_price_2 != "" &&
    product_mrp == ""
  ) {
    return (
      <Button
        variant="contained"
        size="small"
        className="gcip"
        style={PriceButtonCss}
      >
        ₹ {product_price_2}
      </Button>
    );
  } else if (
    product_price == "" &&
    product_price_2 == "" &&
    product_mrp != ""
  ) {
    return (
      <Button
        variant="contained"
        size="small"
        className="gcip"
        style={PriceButtonCss}
      >
        {"₹ " + product_mrp}
      </Button>
    );
  } else if (
    product_price != "" &&
    product_price_2 == "" &&
    product_mrp != ""
  ) {
    return (
      <ButtonGroup>
        <Button
          variant="contained"
          size="small"
          className="gcip"
          style={PriceStrikeCss}
        >
          ₹ {product_mrp}
        </Button>
        <Button
          variant="contained"
          size="small"
          className="gcip"
          style={PriceButtonCss}
        >
          ₹ {product_price}
        </Button>
      </ButtonGroup>
    );
  } else if (
    product_price == "" &&
    product_price_2 != "" &&
    product_mrp != ""
  ) {
    return (
      <ButtonGroup>
        <Button
          variant="contained"
          size="small"
          className="gcip"
          style={PriceStrikeCss}
        >
          ₹ {product_mrp}
        </Button>
        <Button
          variant="contained"
          size="small"
          className="gcip"
          style={PriceButtonCss}
        >
          ₹ {product_price_2}
        </Button>
      </ButtonGroup>
    );
  } else if (
    product_price != "" &&
    product_price_2 != "" &&
    product_mrp == ""
  ) {
    return (
      <Button
        variant="contained"
        size="small"
        className="gcip"
        style={PriceButtonCss}
      >
        ₹ {product_price}
      </Button>
    );
  } else if (
    product_price != "" &&
    product_price_2 != "" &&
    product_mrp != ""
  ) {
    return (
      <ButtonGroup>
        <Button
          variant="contained"
          size="small"
          className="gcip"
          style={PriceStrikeCss}
        >
          ₹ {product_mrp}
        </Button>
        <Button
          variant="contained"
          size="small"
          className="gcip"
          style={PriceButtonCss}
        >
          ₹ {product_price}
        </Button>
      </ButtonGroup>
    );
  } else {
    return (
      <Button
        variant="contained"
        size="small"
        className="gcip"
        style={PriceButtonCss}
      >
        <WhatshotIcon style={{ color: "red" }} /> Get Deal
      </Button>
    );
  }
}
export default function PriceTester(props) {
  const { product_price, product_price_2, product_mrp } = props;
  if (product_price !== undefined && product_price !== null) {
    var product_pric = product_price.toString().replace(/[^\d.$,-]/g, "");
  }
  if (product_price_2 !== undefined && product_price_2 !== null) {
    var product_price_ = product_price_2.toString().replace(/[^\d.$,-]/g, "");
  }
  if (product_mrp !== undefined && product_mrp !== null) {
    var product_mr = product_mrp.toString().replace(/[^\d.$,-]/g, "");
  }
  return <div>{PriceBlock(product_pric, product_price_, product_mr)}</div>;
}
