import React, { Component } from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { fade, makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ActionsOfPage from "../IndexPage/ActionsOfPage";
import Divider from "@material-ui/core/Divider";
import StoresData from "../IndexPage/StoresData";
import "react-lazy-load-image-component/src/effects/blur.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// cards
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Skeleton from "@material-ui/lab/Skeleton";
import CardActions from "@material-ui/core/CardActions";
import PriceTester from "../IndexPage/PriceTester";
// menu
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FlagRoundedIcon from "@material-ui/icons/FlagRounded";
import BugReportRoundedIcon from "@material-ui/icons/BugReportRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import ProductReport from "../IndexPage/ProductReport";
import Img, {
  CloudimageProvider,
  BackgroundImg,
} from "react-cloudimage-responsive";
// const style function
const useStyles = makeStyles((theme) => ({
  BtnShopping: {
    color: "#000",
    backgroundColor: "#fff",
  },

  media: {
    height: 190,
  },
}));
const DivdrStyle = {
  margin: "3px 0px",
  backgroundColor: "rgba(37, 37, 37, 0)",
  borderTop: " 1px dashed #444444",
};
const styles = {
  CardCss: {
    backgroundColor: "burlywood",
    // border: "2px dashed #272727",
    color: "#fff",
  },
  cardMedaCss: {
    backgroundSize: "contain",
    backgroundColor: "#fff",
  },
  CardContentCss: {
    padding: "5px 15px",
  },
  CardHeaderCss: {
    padding: "8px",
    color: "#fff",
  },
  NameTopoCss: {
    color: "#fff",
    fontSize: "14px",
    width: "100%",
    lineHeight: "18px",
    textTransform: "capitalize",
    fontWeight: "600",
    height: "56px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    wordSpacing: "0px",
    /* border-top: 1px solid #424242; */
    fontFamily: "sans-serif",
  },
  moreButtonCssStyle: {
    color: "#fff",
    fontSize: "18px",
    float: "right",
  },
  reportMenuCssStyle: {
    color: "black",
    fontWeight: "600",
    textTransform: "capitalize",
  },
};
const cloudimageConfig = {
  token: "ajpxzukkep",
  baseURL: "https://ajpxzukkep.cloudimg.io/v7/",
};

function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-haspopup="true"
        onClick={handleClick}
        style={styles.moreButtonCssStyle}
        aria-label="More Info"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ProductReport product={props.product} />
        {/* <MenuItem style={styles.reportMenuCssStyle} onClick={handleClose}>
          <BugReportRoundedIcon style={{ marginRight: "5px" }} />
          Report Bug
        </MenuItem> */}
      </Menu>
    </>
  );
}
function LatestProductsListDisplay(props) {
  const function_name = (props) => {};
  function_name.propTypes = {
    state: PropTypes.object.isRequired,
  };
  const classes = useStyles();
  const {
    id,
    product_uuid,
    product_name,
    product_id,
    product_image,
    product_mrp,
    product_price_2,
    product_price,
    date_created,
  } = props.data;
  const dateStr = String(date_created);
  const loading = false;
  const dateCreated = dateStr.substring(0, 10);
  return (
    <>
      <Card
        style={styles.CardCss}
        className="mdl-cell mdl-cell--12-col-desktop mdl-cell--12s-col-tablet mdl-cell--12-col-phone"
      >
        <CardContent style={styles.CardHeaderCss}>
          <StoresData
            productId={props.data.store_id}
            timePosted={dateCreated}
          />
          {/* {props.state.isAuthenticated === true ? (
            <SimpleMenu product={props.data} />
          ) : (
            <div></div>
          )} */}
        </CardContent>
        <Link to={`/item/${id}`}>
          <CardActionArea>
            {loading ? (
              <Skeleton
                animation="wave"
                variant="rect"
                className={classes.media}
              />
            ) : (
              <CardMedia
                className={classes.media}
                image={product_image}
                title={product_name}
                style={styles.cardMedaCss}
              />
            )}

            <CardContent style={styles.CardContentCss}>
              {loading ? (
                <React.Fragment>
                  <Skeleton
                    animation="wave"
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation="wave" height={10} width="80%" />
                </React.Fragment>
              ) : (
                <Typography
                  style={styles.NameTopoCss}
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="gcih"
                >
                  {product_name}
                </Typography>
              )}
              <PriceTester
                product_price={product_price}
                product_price_2={product_price_2}
                product_mrp={product_mrp}
              />
            </CardContent>
          </CardActionArea>
        </Link>
        {/* <Divider style={DivdrStyle} /> */}
        {/* <CardActions>
          <ActionsOfPage
            productId={id}
            ProductLink={product_uuid}
            productContent={props.data}
          />
        </CardActions> */}
      </Card>
    </>
  );
}

const mapStateToProp = (state) => {
  return {
    state: state,
  };
};
export default connect(mapStateToProp)(LatestProductsListDisplay);
