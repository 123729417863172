import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
const StylesData = {
  chipButton: {
    color: "#582a08",
    fontWeight: "600",
    margin: "3px",
    backgroundColor: "#7d42173d",
    margin: "0px",
    fontSize: "14px",
    fontFamily: "inherit",
    border: "0px",
    textTransform: "capitalize",
    borderRadius: "5px",
    width: "fit-content",
  },
  avatarButton: {
    backgroundColor: "#000",
  },
  chipButtonmore: {
    color: "#fff",
    fontWeight: "700",
    margin: "3px",
    backgroundColor: "#582a08",
    margin: "10px 3px 10px 30px",
    fontSize: "12px",
    fontFamily: "inherit",
    border: "1px solid #0000000",
    width: "60%",
    textTranformation: "capitailze",
    textAlign: "center",
    verticalAlign: "middle",
  },
  backimgStyle: {
    height: "100px",
    width: "180px",
    borderRadius: "5px",
    paddingBottom: "30px",
  },
  backimgStyleimg: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    objectFit: "cover",
    backgroundRepeat: "norepeat",
  },
  overlay: {
    position: "absolute",
    height: "110px",
    width: "180px",
    top: "0",
    // background:
    //   "linear-gradient(180deg, #12121275, #121212de, #121212de, #121212)",
  },
  imgStyle: {
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    position: "relative",
    top: "60%",
    padding: "3px",
    backgroundColor: "#fff",
    left: "33.33%",
    // position: "absolute",
  },
  imgStyleimg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
    backgroundRepeat: "norepeat",
  },
  textname: {
    margin: "0px",
    fontSize: "14px",
    textTransform: "capitalize",
    fontWeight: "700",
    textAlign: "center",
    color: "#582a08",
    fontFamily: "inherit",
    verticalAlign: "middle",
  },
  desctext: {
    margin: "0px",
    padding: "3px 5px 10px 10px",
    fontSize: "10px",
    fontWeight: "600",
    maxHeight: "75px",
    overflow: "hidden",
    lineHeight: "14px",
    maxWidth: "160px",
    color: "#582a08",
    fontFamily: "inherit",
    textAlign: "center",
    verticalAlign: "middle",
  },
};
const getValidUrl = (url = "") => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, "");

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://127.0.0.1:8000${newUrl}`;
  }

  return newUrl;
};
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    // padding: theme.spacing(0),
  },
}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "burlywood",
  },
  tooltip: {
    backgroundColor: "burlywood",
    padding: "0px",
    // margin: "0px",
    border: "0px",
  },
}));

export default function StorePopper(props) {
  const {
    id,
    store_unqiue_id,
    store_name,
    store_icon_image,
    store_background_image,
    store_description,
  } = props.productId;
  const murl = getValidUrl(store_icon_image);
  const dateStr = String(props.timePosted);
  const dateCreated = dateStr.substring(0, 10);

  var storeicon_img = `https://ajpxzukkep.cloudimg.io/v7/${store_icon_image}?width=50&height=50&func=fit`;
  var storeback_img = `https://ajpxzukkep.cloudimg.io/v7/${store_background_image}?width=180&height=200`;

  const classes = useStylesBootstrap();

  return (
    <>
      <Tooltip
        arrow
        placement="right-start"
        style={{ height: "auto" }}
        title={
          // <Chip
          //   avatar={
          //     <Avatar
          //       alt={store_name}
          //       src={murl}
          //       style={StylesData.avatarButton}
          //     />
          //   }
          //   label={store_name + " " + "•" + " " + dateCreated}
          //   variant="outlined"
          //   clickable
          //   style={StylesData.chipButton}
          // ></Chip>
          <>
            <div>
              <div style={StylesData.backimgStyle}>
                <img
                  style={StylesData.backimgStyleimg}
                  src={storeback_img}
                  alt={store_name}
                />
              </div>
              <div style={StylesData.overlay}>
                <div style={StylesData.imgStyle}>
                  <img
                    style={StylesData.imgStyleimg}
                    src={storeicon_img}
                    alt={store_name}
                  />
                </div>
              </div>
              <div>
                <h6 style={StylesData.textname}>{store_name}</h6>
              </div>
              <div>
                <h6 style={StylesData.desctext}>{store_description}</h6>
              </div>
              <div>
                <Link to={`/store/${id}`}>
                  <Chip
                    label={"know More"}
                    variant="outlined"
                    size="small"
                    clickable
                    style={StylesData.chipButtonmore}
                    icon={<ArrowRightAltIcon style={{ color: "white" }} />}
                  ></Chip>
                </Link>
              </div>
            </div>
          </>
        }
        classes={classes}
        {...props}
        interactive
      >
        <Link to={`/store/${id}`}>
          <Chip
            avatar={
              <Avatar
                alt={store_name}
                src={storeicon_img}
                style={StylesData.avatarButton}
              />
            }
            label={store_name + " " + "•" + " " + dateCreated}
            variant="outlined"
            clickable
            style={StylesData.chipButton}
          ></Chip>
        </Link>
      </Tooltip>
    </>
  );
}
