import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ItemForm from "./ItemForm";
import DescribeForm from "./DescribeForm";
import PriceForm from "./PriceForm";
import Button from "@material-ui/core/Button";
const Styles = {
  UploadBtnCss: {
    margin: "5px 5px 5px 10px",
    border: "1px dashed grey",
    maxWidth: "300px",
    textTranform: "capitalize",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  input: {
    display: "none",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));
const ProductStep2 = ({ setForm, formData }) => {
  const classes = useStyles();

  const { imgurl, producturl, storeurl } = formData;

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <ItemForm
          name="imgurl"
          label="Product Image Url"
          value={imgurl}
          onChange={setForm}
          helperText="Enter Url of image that you wanted to add"
        />
        <ItemForm
          name="producturl"
          label="Product Url"
          helperText="Enter Url of product that you wanted to Link to"
          value={producturl}
          onChange={setForm}
        />
        <ItemForm
          name="storeurl"
          label="Store Id"
          helperText="Leave blank if you not added store our stores list"
          value={storeurl}
          onChange={setForm}
        />
      </form>
    </>
  );
};

export default ProductStep2;
